import React, { FC, useEffect } from 'react'
import useForm from '@hooks/useForm'
import GenericFormPage from '@templates/Generic/Form'
import { Button } from 'gatsby-material-ui-components'
import {
  ConfigurationResponse,
  getConfiguration,
  updateConfiguration
} from '@utils/api'
import { Grid } from '@mui/material'
import useApi from '@hooks/useApi'
import { PageProps } from 'gatsby'
import {
  TFormConfig,
  initialValues,
  initialValidation,
  FormUpdateConfig
} from '@components/Form/FormConfiguration'

const SettingsServerConfigPage: FC<PageProps> = ({ params }) => {
  const api = useApi<ConfigurationResponse>({
    apiMethod: getConfiguration,
    params: params.username
  })

  const form = useForm<TFormConfig>({
    initialValues,
    initialValidationRules: initialValidation
  })

  const updateApi = useApi<ConfigurationResponse>({
    apiMethod: updateConfiguration,
    requestOnMount: false
  })

  useEffect(() => {
    if (api.response) {
      form.setValues({
        ...api.response
      })
    }
  }, [api.response])

  return (
    <GenericFormPage
      title="Server Configuration"
      breadcrumbs={[
        {
          title: 'Settings',
          to: '/settings/'
        },
        {
          title: 'Server Configuration',
          to: '/settings/server-configuration/'
        }
      ]}
      form={form as any}
      api={updateApi}
      formActions={
        <Grid container spacing={2}>
          <Grid item>
            <Button
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              disabled={api.loading}
            >
              Update Config
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="text"
              onClick={() => window !== undefined && window.history.back()}
            >
              {'back'}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <FormUpdateConfig form={form as any} />
    </GenericFormPage>
  )
}

export default SettingsServerConfigPage
