import { TextField } from '@components'
import useForm, { ValidationRule } from '@utils/hooks/useForm'
import { Configuration } from '@utils/api'
import React, { ReactElement } from 'react'
import { Stack } from '@mui/material'

export type TFormConfig = Configuration

interface Props {
  form: ReturnType<typeof useForm>
}

export const initialValues: TFormConfig = {
  config_revision: 0,
  elastic_replication_url: ''
}

export const initialValidation: ValidationRule<TFormConfig> = {
  config_revision: (value) => Number.isInteger(value),
  elastic_replication_url: (value) =>
    !value ||
    !!value.match(
      new RegExp(
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
      )
    )
}

export function FormUpdateConfig({ form }: Props): ReactElement {
  return (
    <>
      <Stack spacing={3}>
        <TextField
          id="elastic_replication_url"
          label="Elastic Replication URL"
          form={form}
        />
      </Stack>
      <input
        type="hidden"
        name="config_revision"
        value={form && form.values.config_revision}
      />
    </>
  )
}

export function validateConfiguration(form: ReturnType<typeof useForm>) {
  return form.validateFields(['config_revision', 'elastic_replication_url'])
}
